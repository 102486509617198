/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Modal from "./modal"
import Accordion from "./accordion"
import Container from "./container"
import { useStaticQuery, graphql } from "gatsby"
import ContentCard from "./content-card"
import Link from "./link"

const Header = () => {
  const { site } = useStaticQuery(graphql`
    {
      site: contentfulMinisite(slug: { eq: "sha" }) {
        heroBackground {
          file {
            url
          }
        }
        heroShaLogo {
          file {
            url
          }
        }
        propaneLogo {
          file {
            url
          }
        }
      }
    }
  `)
  return (
    <header
      sx={{
        width: "100%",
        height: "500px",
        backgroundImage: `url('${site?.heroBackground?.file?.url}')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "none",
      }}
    >
      <div sx={{ position: "absolute", top: "15px", left: "15px" }}>
        <Link link="/" sx={{ alignSelf: "center" }}>
          <img
            src={site?.propaneLogo?.file?.url}
            alt="Sutter Health"
            sx={{
              width: "150px",
            }}
          />
        </Link>
      </div>
      <div
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={site?.heroShaLogo?.file?.url}
            alt="Sutter Health"
            sx={{
              width: "200px",
              mb: "30px",
            }}
          />
          <h2
            sx={{ color: "white", fontSize: ["20px", "24px"], fontWeight: 500 }}
          >
            Member Portal Flow Launcher
          </h2>
        </div>
      </div>
    </header>
  )
}

export default Header
