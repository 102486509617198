/** @jsx jsx */
import { jsx } from "theme-ui"
import Container from "./container"
import { useStaticQuery, graphql } from "gatsby"
import ContentCard from "./content-card"
import Link from "./link"

const Footer = () => {
  const {
    contentfulSite: { footerLinks },
    site: { propaneLogo },
  } = useStaticQuery(graphql`
    {
      contentfulSite {
        footerLinks {
          text
          url
          reference {
            ... on ContentfulLayoutPage {
              slug
            }
            ... on ContentfulLayoutCaseStudy {
              slug
            }
          }
        }
      }
      site: contentfulMinisite(slug: { eq: "sha" }) {
        propaneLogo {
          file {
            url
          }
        }
      }
    }
  `)
  return (
    <footer
      sx={{
        background: "#000",
        color: "white",
      }}
    >
      <Container sx={{ py: "40px" }}>
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: ["center", "flex-start"],
            alignItems: ["center", "flex-start"],
          }}
        >
          <div sx={{ mb: "30px" }}>
            <img src={propaneLogo.file.url} />
          </div>
          <div
            sx={{
              display: "flex",
              flexDirection: ["column", "row"],
              textAlign: "center",
            }}
          >
            {footerLinks.map(link => (
              <Link
                sx={{
                  display: "block",
                  mr: "30px",
                  color: "white",
                  fontWeight: 600,
                  pb: "8px",
                  borderBottom: "2px solid transparent",
                  transition: "0.2s",
                  ":hover": {
                    color: "white",
                    borderColor: "white",
                  },
                }}
                link={
                  "https://propane.agency" +
                  (link.url || "/" + link?.reference?.slug)
                }
              >
                {link.text}
              </Link>
            ))}
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
