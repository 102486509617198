import React from "react"
import { Global, css } from "@emotion/core"

import openSansExtraBold from "../fonts/OpenSans-ExtraBold.woff"
import openSansBold from "../fonts/OpenSans-Bold.woff"
import openSansItalic from "../fonts/OpenSans-Italic.woff"
import openSansLight from "../fonts/OpenSans-Light.woff"
import openSansRegular from "../fonts/OpenSans-Regular.woff"

const Fonts = () => (
  <Global
    styles={css`
      @font-face {
        font-display: swap;
        font-family: Open sans;
        font-style: normal;
        font-weight: 300;
        src: url(${openSansLight}) format("woff");
      }

      @font-face {
        font-display: swap;
        font-family: Open sans;
        font-style: normal;
        font-weight: 400;
        src: url(${openSansRegular}) format("woff");
      }

      @font-face {
        font-display: swap;
        font-family: Open sans;
        font-style: normal;
        font-weight: 500;
        src: url(${openSansRegular}) format("woff");
      }

      @font-face {
        font-display: swap;
        font-family: Open sans;
        font-style: italic;
        font-weight: 400;
        src: url(${openSansItalic}) format("woff");
      }

      /* BOLD */
      @font-face {
        font-display: swap;
        font-family: Open sans;
        font-style: normal;
        font-weight: 600;
        src: url(${openSansBold}) format("woff");
      }

      @font-face {
        font-display: swap;
        font-family: Open sans;
        font-style: normal;
        font-weight: 900;
        src: url(${openSansExtraBold}) format("woff");
      }
    `}
  />
)

export default Fonts
